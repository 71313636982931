<template>
    <div>
        <datatable-filter
            v-show="datatable.filterStatus"
            @filter="filter"
            @filterClear="filterClear"
            :exportExcel="checkPermission('reservation_conflictexcelexport')"
            @exportExcel="getRowsExcel()"
        >
            <ValidationObserver ref="conflictFormValidate">
                <b-row>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('classroom')">
                            <classroom-selectbox
                                v-model="datatable.queryParams.filter.classroom_id"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('start_date') + ' / ' + $t('end_date')">
                            <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                                <v-date-picker
                                    v-model="datatable.queryParams.filter.date"
                                    is-range
                                    :locale="'en'"
                                    :model-config="{type: 'string', mask: 'MM/DD/YYYY'}"
                                >
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <div class="border rounded-sm p-1 p-md-3 d-flex align-items-center" :class="errors[0] ? 'box-border-color':''">
                                            <div class="flex-grow-1">
                                                <b-form-group class="m-0 mr-1">
                                                    <input
                                                        :value="inputValue.start"
                                                        v-on="inputEvents.start"
                                                        :placeholder="$t('start_date')"
                                                        class="form-control"
                                                    />
                                                </b-form-group>
                                            </div>
                                            <div class="flex-grow-1">
                                                <b-form-group class="m-0 ml-1">
                                                    <input
                                                        :value="inputValue.end"
                                                        v-on="inputEvents.end"
                                                        :placeholder="$t('end_date')"
                                                        class="form-control"
                                                    />
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </template>
                                </v-date-picker>
                            </ValidationProvider>
                        </b-form-group>
                        <b-form-group :label="$t('start_time')+' / '+$t('end_time')">
                            <time-picker
                                v-model="datatable.queryParams.filter.time"
                                :minute-increment="5"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('reservation_type')">
                            <parameter-selectbox
                                v-model="datatable.queryParams.filter.type"
                                code="reservation_types"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </datatable-filter>
        <datatable
            :isLoading.sync="datatable.isLoading"
            :columns="datatable.columns"
            :rows="datatable.rows"
            :total="datatable.total"
            :queryParams="datatable.queryParams"
            :showPaginate="datatable.showPaginate"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-per-page-change="onPerPageChange"
            v-show="datatable.showTable"
        />
    </div>
</template>

<script>
    // Component
    import BuildingSelectbox from '@/components/interactive-fields/BuildingSelectbox'
    import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox'
    import ClassroomSelectbox from '@/components/interactive-fields/ClassroomSelectbox'
    import Datatable from '@/components/datatable/Datatable'
    import DatatableFilter from '@/components/datatable/DatatableFilter'
    import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'
    import TimePicker from '@/components/elements/TimePicker'

    // Services
    import ReservationService from '@/services/ReservationService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import qs from 'qs'

    export default {
        components: {
            BuildingSelectbox,
            CampusSelectbox,
            ClassroomSelectbox,
            DatatableFilter,
            Datatable,
            ParameterSelectbox,
            TimePicker,
            ValidationObserver,
            ValidationProvider,
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    showPaginate: false,
                    columns: [
                        {
                            label: this.toUpperCase(this.spaceToBR(this.$t('campus'))),
                            field: 'campus',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.campus
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('building')),
                            field: 'building',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.building
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('classroom')),
                            field: 'classroom',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.classroom
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('date')),
                            field: 'date',
                            sortable: true,
                            formatFn: (value) => {
                                return value ? this.toLocaleDate(value) : '-';
                            }
                        },
                        {
                            label: this.toUpperCase(this.spaceToBR(this.$t('start_time'))),
                            field: 'start_time',
                            sortable: false
                        },
                        {
                            label: this.toUpperCase(this.spaceToBR(this.$t('end_time'))),
                            field: 'end_time',
                            sortable: false
                        },
                        {
                            label: this.toUpperCase(this.$t('event_type')),
                            field: 'type',
                            sortable: false,
                            formatFn: (value, row) => {
                                return row.type_text;
                            }
                        },
                        {
                            label: this.toUpperCase(this.$t('event_owner')),
                            field: 'instructor',
                            sortable: false
                        }
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {
                            campus_id: null,
                            classroom_id: null,
                            date: {start: null, end: null},
                            //date: {start: this.getDate(), end: this.getDate()},
                            time: null,
                            type: null
                        },
                        page: 1,
                        limit: 20
                    }
                }
            }
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {
                    campus_id: null,
                    classroom_id: null,
                    date: {start: null, end: null},
                    time: null,
                    type: null
                }
                this.getRows(true);
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field;
                this.getRows();
            },
            async getRows(filterClear) {

                const isValid = await this.$refs.conflictFormValidate.validate();

                // Filters
                let filters = {...this.datatable.queryParams.filter}

                if (!isValid || !filters.date || (filters.date && !filters.date.start && !filters.date.end)) {
                    this.datatable.showTable = false;
                    this.datatable.isLoading = false;

                    this.datatable.queryParams.filter.date = filterClear ? {} : null

                    return
                }

                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                let config = this.getConfig();

                return ReservationService.conflictReport(config)
                                         .then((response) => {
                                             this.datatable.rows = response.data.data;
                                         })
                                         .finally(() => {
                                             this.datatable.isLoading = false;
                                         });
            },
            getRowsExcel() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                let config = this.getConfig();

                return ReservationService.conflictReportExcel(config)
                                         .then((response) => {
                                             let data = response.data.data;
                                             if(data.log_id){
                                                 let path = 'job/logs/detail/'+data.log_id;
                                                 this.openInNewWindow(path);
                                             }
                                         })
                                         .catch(e => {
                                             this.showErrors(e);
                                         });
            },
            getConfig(){
                // Filters
                let filters = {...this.datatable.queryParams.filter}

                // Modify Filters
                let modifyFilters = {}

                // Campus Id
                if (filters.campus_id) {
                    modifyFilters.campus_id = filters.campus_id
                }

                // Classroom Id
                if (filters.classroom_id) {
                    modifyFilters.classroom_id = filters.classroom_id
                }

                // Date
                if (filters.date) {
                    if (filters.date.start == filters.date.end) {
                        modifyFilters.date = filters.date.start;
                    }
                    else {
                        modifyFilters.date = filters.date.start + ',' + filters.date.end;
                    }
                }

                // Time
                if (filters.time) {
                    modifyFilters.start_time = filters.time.start
                    modifyFilters.end_time = filters.time.end
                }

                // Time
                if (filters.type) {
                    modifyFilters.type = filters.type
                }

                return {
                    params: {
                        sort: this.datatable.queryParams.sort,
                        limit: this.datatable.queryParams.limit,
                        filter: {
                            ...modifyFilters
                        }
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }
            },
        }
    }
</script>
