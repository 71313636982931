<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="4">
                    <ValidationProvider name="campus_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox v-model="formData.campus_id"
                                              :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="building_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('building_id')">
                            <building-selectbox v-model="formData.building_id"
                                                :campus_id="formData.campus_id"
                                                :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="classroom_id" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('classroom')">
                            <classroom-selectbox v-model="formData.classroom_id"
                                                 :building_id="formData.building_id"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('date')">
                            <select-date v-model="formData.date"
                                         :validation-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="time" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('start_time')+' / '+$t('end_time')">
                            <time-picker v-model="formData.time"
                                         :minute-increment="5"
                                         :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="repeat" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('repeat')">
                            <true-false-selectbox v-model="formData.repeat"
                                                  :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <b-row v-if="formData.repeat">
                        <b-col>
                            <ValidationProvider name="repeat_type" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('repeat_type')">
                                    <parameter-selectbox v-model="formData.repeat_type"
                                                         code="reservation_repeat_types"
                                                         :validate-error="errors[0]"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider name="repeat_count" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('repeat_count')">
                                    <b-form-input v-model="formData.repeat_count"
                                                  type="number"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" md="4">
                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('event_type')">
                            <parameter-selectbox v-model="formData.type"
                                                 code="reservation_types"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="instructor_id" v-slot="{valid, errors}">
                        <b-form-group :label="$t('event_owner')">
                            <staff-auto-complete v-model="formData.instructor_id"
                                                 code="reservation_types"
                                                 return-value="id"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('explanation')">
                            <b-form-textarea no-resize
                                             rows="4"
                                             v-model="formData.explanation"
                                             :state="errors[0] ? false : null"
                            />
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>

            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
        <hr>
        <vue-cal :selected-date="startDate"
                 :time-cell-height="30"
                 :time-step="30"
                 :disable-views="['years', 'year', 'month','days','day']"
                 active-view="week"
                 :hide-weekdays="[]"
                 hide-title-bar
                 :events="events"
                 :locale="$i18n.locale"
                 :time-from="8 * 60"
                 :time-to="21 * 60"
                 :on-event-click="showVueCalModal"
        >
        </vue-cal>
        <CommonModal ref="vueCalModal" size="sm">
            <template v-slot:CommonModalTitle>
                <i style="font-size: 24px;" class="ri-calendar-event-line"></i>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <b-row>
                        <b-col md="12" class="mb-2">{{selectedEvent.date}}</b-col>
                        <b-col md="12" class="mb-2">{{selectedEvent.title}}</b-col>
                        <b-col md="12" class="mb-2">{{selectedEvent.start_time}} - {{selectedEvent.end_time}}</b-col>
                        <b-col md="12" v-html="selectedEvent.content"></b-col>
                    </b-row>
                </div>
            </template>
        </CommonModal>

    </div>
</template>

<script>
// Components
import BuildingSelectbox from '@/components/interactive-fields/BuildingSelectbox'
import CampusSelectbox from '@/components/interactive-fields/CampusSelectbox'
import ClassroomSelectbox from '@/components/interactive-fields/ClassroomSelectbox'
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import SelectDate from '@/components/interactive-fields/SelectDate';
import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
import TimePicker from '@/components/elements/TimePicker';
import TrueFalseSelectbox from '@/components/interactive-fields/TrueFalseSelectbox';
import CommonModal from "@/components/elements/CommonModal";

// Services
import ReservationService from '@/services/ReservationService';

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import '/src/locales/en/vuecal.js'
import moment from "moment";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,

        BuildingSelectbox,
        CampusSelectbox,
        ClassroomSelectbox,
        ParameterSelectbox,
        SelectDate,
        StaffAutoComplete,
        TimePicker,
        TrueFalseSelectbox,
        VueCal,
        CommonModal
    },
    props: {
        startData: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            formData: {
                campus_id: null,
                building_id: null,
                classroom_id: null,
                date: null,
                time: null,
                repeat: false,
                repeat_type: null,
                repeat_count: null,
                type: null,
                instructor_id: null,
                explanation: null
            },
            formLoading: false,

            startDate: null,
            events: [],
            selectedEvent: {}
        }
    },
    created() {
        if (this.startData) {
            if (this.startData.campus_id) {
                this.formData.campus_id = this.startData.campus_id
            }
            if (this.startData.building_id) {
                this.formData.building_id = this.startData.building_id
            }
            if (this.startData.classroom_id) {
                this.formData.classroom_id = this.startData.classroom_id
            }
            if (this.startData.date) {
                this.formData.date = this.startData.date
            }
            if(this.startData.time){
                this.formData.time = this.startData.time
            }
        }

    },

    watch: {
        formData: {
            handler(val) {
                this.changeDate(this.formData)
            },
            deep: true
        },

    },

    methods: {
        async createForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                this.formLoading = true;

                let formData = {
                    classroom_id: this.formData.classroom_id,
                    date: this.formData.date,
                    start_time: this.formData.time && this.formData.time.start ? this.formData.time.start : null,
                    end_time: this.formData.time && this.formData.time.end ? this.formData.time.end : null,
                    type: this.formData.type,
                    instructor_id: this.formData.instructor_id,
                    explanation: this.formData.explanation
                }
                if (this.formData.repeat) {
                    formData.repeat_type = this.formData.repeat_type
                    formData.repeat_count = this.formData.repeat_count
                }

                ReservationService.store(formData)
                                  .then(response => {
                                      this.$toast.success(this.$t('api.' + response.data.message));
                                      this.$emit('createFormSuccess', true);
                                  })
                                  .catch(e => {
                                      this.showErrors(e, this.$refs.formModalValidate);
                                  })
                                  .finally(() => {
                                      this.formLoading = false
                                  })

            }
        },

        changeDate(obj) {
            this.events = []
            this.startDate = moment(obj.date).startOf('week').add(+1, 'days').format('MM/DD/YYYY')
            obj.time = obj.time == null ? {start: '00:00', end: '00:00'} : obj.time
            this.events.push({
                start: moment(obj.date).format('MM/DD/YYYY') + ' ' + obj.time.start,
                end: moment(obj.date).format('MM/DD/YYYY') + ' ' + obj.time.end,
                title: obj.type == null ? this.$t('new_event') : obj.type,
                content: '<i class="ri-pushpin-fill"></i>',
                class: 'sport'
            })
            if(this.formData.classroom_id){
                this.getReservations(this.formData.classroom_id, obj.date)
            }

        },

        getReservations(classroomId, startDate) {
            const config = {
                classroom_id: classroomId,
                date: startDate
            }
            ReservationService.classroomSchedule(config).then(response => {
                if (Object.values(response.data.data).length) {
                    Object.values(response.data.data)
                          .forEach((day) => {
                              if (day.length) {
                                  day.forEach((lesson) => {
                                      this.events.push({
                                          start: lesson.date + ' ' + lesson.start_time,
                                          end: lesson.date + ' ' + lesson.end_time,
                                          title: lesson.name,
                                          content: ''
                                      });
                                  })
                              }
                          })
                }
            })
        },

        showVueCalModal(event, e){
            this.selectedEvent = event
            moment.locale('en')
            this.selectedEvent.date = moment(event.start).format("DD-MM-YYYY")
            this.selectedEvent.start_time = moment(event.start).format("LT")
            this.selectedEvent.end_time = moment(event.end).format("LT")
            this.$refs.vueCalModal.$refs.commonModal.show()
            e.stopPropagation()
        }
    }
}
</script>
<style>

.vuecal__event.sport {
    background-color: rgba(255, 102, 102, 0.9);
    border: 1px solid rgb(235, 82, 82);
    color: #fff;
}
</style>

